import React from "react";
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews";
import styled from "styled-components";

const Page = styled.div`
  & .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    height: 75px;

    transition: 249ms background-color ease;
    background-color: #bdbfb7;

    z-index: 1;

    @media (max-width: 800px) {
      height: 56px;
    }
  }

  & .link {
    color: #f9e0d2;

    & a {
      transition: 250ms color ease;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: #000;

          & svg {
            &.desktop-arrow {
              & path {
                stroke: #000;
              }
            }

            &.mobile-arrow {
              & path {
                fill: #000;
              }
            }
          }
        }
      }
    }

    & svg {
      width: 98px;
      margin: 0 0 0 20px;

      & path {
        transition: 250ms stroke ease;
      }

      &.mobile-arrow {
        display: none;

        & path {
          fill: #f9e0d2;
        }
      }

      @media (max-width: 800px) {
        &.desktop-arrow {
          display: none;
        }

        &.mobile-arrow {
          display: inline-block;
          width: auto;

          margin: 0 0 0 15px;
        }
      }
    }
  }
`;

const NotFoundPage = () => {
  return (
    <>
      <Page>
        <p>The work you are looking for might no longer be available.</p>
      </Page>
    </>
  );
};

export default withPrismicUnpublishedPreview(NotFoundPage);
